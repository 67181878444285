/**BOX STORAGE START**/

.be-logo {
  padding-left: 20px;
}

.be-logo .be-logo-custom {
  max-height: 32px;
  max-width: 80px;
}

.be-is-small .be-logo .be-logo-custom {
  max-width: 75px;
}

.be-logo .be-logo-placeholder {
  align-items: center;
  background-color: #e8e8e8;
  border: 1px dashed;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 75px;
}

.be-is-small .be-logo .be-logo-placeholder {
  width: 60px;
}

.be-logo .be-logo-placeholder span {
  font-size: 10px;
  text-transform: uppercase;
}

.be-logo svg {
  display: block;
}

.be .be-header {
  align-items: center;
  background: #fcfcfc;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex: 0 0 70px;
  padding: 0 25px 0 0;
}

.be-is-small .be .be-header {
  padding-right: 20px;
}

.be .be-header .be-search {
  flex: 1;
  padding-left: 20px;
}

.be .be-header input[type='search'] {
  -webkit-appearance: none;
  font: inherit;
  width: 100%;
}

.be-breadcrumb {
  align-items: center;
  display: flex;
  min-width: 20px;
}

.be-breadcrumb:only-child {
  min-width: 64px;
}

.be .be-breadcrumb .btn-plain {
  color: #767676;
}

.be .be-breadcrumb .btn-plain:last-child {
  color: #222;
}

.be-breadcrumb-seperator {
  flex-shrink: 0;
  margin: 1px 8px 0 5px;
}

.bdl-Breadcrumb-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

/*animated scroll arrow animation*/

@keyframes shimmer {
  0% { background-position: -200px 0; }
  100% { background-position: 200px 0; }
}
@keyframes fade_move_down {
  0% {
    transform: translate(0, -2px) rotate(0deg);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 5px) rotate(0deg);
    opacity: 0;
  }
}

@keyframes fade_move_up {
  0% {
    transform: translate(0, 5px) rotate(0deg);
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translate(0, -2px) rotate(0deg);
    opacity: 0;
  }
}
/*animated scroll arrow animation*/

@keyframes playScript {
  0% {
    transform: translateY(0px);
}
100% {
    transform: translateY(calc(-100% + 21px));
}
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(1deg);
  }
  25% {
    -ms-transform: rotate(-1deg);
  }
  50% {
    -ms-transform: rotate(1.5deg);
  }
  75% {
    -ms-transform: rotate(-5deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Chat blob notification animation keyframes */
@keyframes bounce {
  0%,
  20%,
  53%,
  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
}

/*animated sbackground ripple animation*/
@keyframes rippleBackground {
  from {
    background-position: right bottom;
    background-size: 200% 100%;
  }

  to {
    background-position: left bottom;
    background-size: 200% 100%;
  }
}

@keyframes dropdown_menu_right_aligned {
  0% {
    transform: rotateY(-10deg) rotateX(-10deg);
  }
}

@keyframes dropdown_menu_left_aligned {
  0% {
    transform: rotateY(10deg) rotateX(-10deg);
  }
}

@keyframes jumpInfinite {
  0% {
    margin-top: -3px;
  }
  50% {
    margin-top: 7px;
  }
  100% {
    margin-top: -3px;
  }
}

.dropdown-menu-element-attached-right .aria-menu {
  animation: dropdown_menu 0.125s, dropdown_menu_right_aligned 0.125s;
  transform-origin: right top;
}

.dropdown-menu-element-attached-left .aria-menu {
  animation: dropdown_menu 0.125s, dropdown_menu_left_aligned 0.125s;
  transform-origin: left top;
}

.dropdown-menu-element {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #222;

  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin-top: 5px;
  perspective: 500px;
  perspective-origin: top;
  text-rendering: optimizeLegibility;
  z-index: 170;
}

.dropdown-menu-element,
.dropdown-menu-element *,
.dropdown-menu-element :after,
.dropdown-menu-element :before {
  box-sizing: border-box !important;
}

.aria-menu {
  background: #fff;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 8px 0;
  white-space: nowrap;
}

.aria-menu:not(.should-outline-focus):focus {
  outline: none;
}

.aria-menu [role='separator'] {
  border: 0;
  border-top: 1px solid #bcbcbc;
  height: 0;
  margin: 8px 0;
}

.aria-menu .lnk,
.aria-menu .lnk:focus,
.aria-menu .lnk:hover,
.aria-menu a,
.aria-menu a:focus,
.aria-menu a:hover {
  text-decoration: none;
}

.menu-item {
  align-items: center;
  color: #4e4e4e;
  cursor: pointer;
  display: flex;
  min-height: 30px;
  position: relative;
}

.menu-item,
.menu-item.btn-plain,
.menu-item.btn-plain:active,
.menu-item.btn-plain:hover {
  padding: 5px 35px 5px 15px;
}

.menu-item.menu-section-header {
  color: #000;
  font-size: 10px;
  font-weight: 700;
}

.menu-item[aria-disabled] {
  cursor: default;
  opacity: 0.4;
}

.menu-item.is-select-item {
  padding-left: 30px;
  position: relative;
}

.menu-item.is-select-item.is-selected:before {
  border-color: #4e4e4e;
  border-style: none solid solid none;
  border-width: 2px;
  content: '';
  height: 12px;
  left: 14px;
  margin: auto;
  position: absolute;
  top: 8px;
  transform: rotate(45deg);
  width: 6px;
}

.menu-item:not([aria-disabled]):focus,
.menu-item:not([aria-disabled]):hover {
  background-color: #e8e8e8;
  color: #4e4e4e;
}

.menu-item:not([aria-disabled]):focus {
  outline: 1px dashed #222;
}

.menu-item svg {
  margin-right: 10px;
}

.radar {
  position: relative;
}

.radar .radar-dot:before {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 8px;
  left: -4px;
  top: -4px;
  width: 8px;
  z-index: 193;
}

.radar .radar-dot:after,
.radar .radar-dot:before {
  background-color: #0061d5;
  content: ' ';
  position: absolute;
}

.radar .radar-dot:after {
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ripple;
  animation-timing-function: ease;
  border-radius: 50%;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  height: 10px;
  width: 10px;
  z-index: 192;
}

.radar .radar-circle:before {
  border: 1px solid #0061d5;
  border-radius: 50%;
}

.radar .radar-circle:after,
.radar .radar-circle:before {
  content: ' ';
  height: 40px;
  left: -20px;
  position: absolute;
  top: -20px;
  width: 40px;
  z-index: 192;
}

.radar .radar-circle:after {
  background-color: #0061d5;
  border-radius: 50%;
  opacity: 0.3;
}

@keyframes ripple {
  0% {
    height: 0;
    left: 0;
    opacity: 0.75;
    top: 0;
    width: 0;
  }

  to {
    height: 40px;
    left: -20px;
    opacity: 0;
    top: -20px;
    width: 40px;
  }
}

.radar-animation-target-attached-bottom.radar-animation-target-attached-center
  > .radar,
.radar-animation-target-attached-bottom.radar-animation-target-attached-left
  > .radar,
.radar-animation-target-attached-bottom.radar-animation-target-attached-right
  > .radar {
  margin-top: 1px;
}

.radar-animation-target-attached-middle.radar-animation-target-attached-right
  > .radar {
  margin-left: 1px;
}

.radar-animation-target-attached-middle.radar-animation-target-attached-center
  > .radar {
  margin-top: 1px;
}

.radar-animation-target-attached-middle.radar-animation-target-attached-left
  > .radar {
  margin-right: 1px;
}

.radar-animation-target-attached-top.radar-animation-target-attached-center
  > .radar,
.radar-animation-target-attached-top.radar-animation-target-attached-left
  > .radar,
.radar-animation-target-attached-top.radar-animation-target-attached-right
  > .radar {
  margin-bottom: 1px;
}

.radar-animation-element {
  z-index: 190;
}

.be .btn-plain.be-breadcrumbs-drop-down {
  color: #767676;
  letter-spacing: 1.5px;
}

.be .btn-plain.be-breadcrumbs-drop-down.be-breadcrumbs-condensed {
  letter-spacing: inherit;
}

.be-breadcrumbs {
  align-items: center;
  display: flex;
  min-width: 0;
  padding-right: 20px;
}

.be-breadcrumb-more {
  align-items: center;
  display: flex;
  min-width: 35px;
}

.be-inline-breadcrumbs {
  color: #767676;
  display: flex;
  font-size: 11px;
  min-width: 0;
}

.be-inline-breadcrumbs .be-breadcrumbs {
  padding-right: 0;
}

.be-inline-breadcrumbs .be-breadcrumb:first-of-type {
  min-width: 43px;
}

.be .be-inline-breadcrumbs .be-breadcrumb .btn-plain {
  color: inherit;
  font-size: 11px;
}

.be-inline-breadcrumbs .be-breadcrumb-more {
  min-width: 16px;
}

.crawler {
  font-size: 0;
  margin: 0 auto;
  white-space: nowrap;
  width: 10px;
}

.crawler div {
  animation: crawler 0.66s ease-in-out infinite;
  background-color: #0061d5;
  border-radius: 4px;
  display: inline-block;
  height: 10px;
  width: 2px;
}

.btn-primary .crawler div {
  background-color: #fff;
}

.crawler.is-small {
  transform: scale(0.7);
}

.crawler.is-medium {
  transform: scale(1.5);
}

.crawler.is-large {
  transform: scale(2);
}

.is-loading .crawler div {
  transform: translateZ(0);
  will-change: transform, opacity;
}

.crawler div:nth-of-type(2) {
  animation-delay: 0.1s;
  margin-left: 2px;
}

.crawler div:last-child {
  animation-delay: 0.2s;
  left: 8px;
  margin-left: 2px;
}

@keyframes crawler {
  0%,
  80%,
  to {
    opacity: 0.5;
    transform: scaleY(1.2);
  }

  40% {
    opacity: 1;
    transform: scaleY(1.6);
  }
}

.loading-indicator-wrapper {
  position: relative;
}

.loading-indicator-wrapper .loading-indicator-veil {
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.loading-indicator-wrapper .loading-indicator-veil.is-with-center-crawler {
  align-items: center;
}

.loading-indicator-wrapper .loading-indicator-veil.blur-content {
  background-color: hsla(0, 0%, 98.4%, 0.75);
}

.loading-indicator-wrapper .loading-indicator-veil.hide-content {
  background-color: #fff;
}

.btn.be-btn-sort {
  padding: 7px 6px;
}

.bce:not(.be-is-small) .btn.be-btn-sort {
  display: none;
}

.btn.be-btn-sort svg {
  display: block;
}

.be-sort-selected {
  display: flex;
  flex: 0 0 26px;
}

.be-menu-sort.aria-menu .menu-item {
  padding-right: 45px;
}

.btn.be-btn-add {
  margin-left: 7px;
  padding: 5px 6px;
}

.btn.be-btn-add svg {
  display: block;
}

.bdl-GridViewSlider {
  display: flex;
  margin-right: 5px;
}

.bdl-GridViewSlider-range {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-left: 7px;
  margin-right: 7px;
}

.bdl-GridViewSlider-range::-webkit-slider-runnable-track {
  background: #e8e8e8;
  border: none;
  border-radius: 2px;
  height: 2px;
}

.bdl-GridViewSlider-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #4e4e4e;
  border: none;
  border-radius: 50%;
  height: 10px;
  margin-top: -4px;
  width: 10px;
}

.bdl-GridViewSlider-range::-moz-range-track {
  background: #e8e8e8;
  border: none;
  border-radius: 2px;
  height: 2px;
}

.bdl-GridViewSlider-range::-moz-range-thumb {
  background: #4e4e4e;
  border: none;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.bdl-GridViewSlider-range::-ms-track {
  background: #e8e8e8;
  border: none;
  border-radius: 2px;
  color: transparent;
  height: 2px;
}

.bdl-GridViewSlider-range::-ms-fill-lower,
.bdl-GridViewSlider-range::-ms-fill-upper {
  background: #e8e8e8;
  border-radius: 10px;
}

.bdl-GridViewSlider-range::-ms-thumb {
  background: #4e4e4e;
  border: none;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.bdl-GridViewSlider-range:-moz-focusring {
  outline: 1px solid #fff;
  outline-offset: -1px;
}

.bdl-GridViewSlider-range:focus {
  outline: none;
}

.bdl-GridViewSlider-range:focus::-ms-fill-lower {
  background: #909090;
}

.bdl-GridViewSlider-range:focus::-ms-fill-upper {
  background: #d3d3d3;
}

.bdl-GridViewSlider-button {
  align-items: center;
  display: flex;
  justify-content: center;
}

.bdl-ViewModeChangeButton {
  padding: 5px 6px;
}

.bdl-ViewModeChangeButton svg {
  display: block;
}

.be-sub-header-right {
  align-items: center;
  display: flex;
}

.be-sub-header-right .bdl-ViewModeChangeButton,
.be-sub-header-right .be-btn-sort {
  margin-left: 7px;
}

.be-sub-header {
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 0 4px 6px -2px rgba(51, 51, 51, 0.04);
  display: flex;
  flex: 0 0 50px;
  justify-content: space-between;
  padding: 0 20px 0 25px;
}

.be-is-small .be-sub-header {
  padding: 0 15px 0 18px;
}

.bce:not(.be-is-small) .be-sub-header {
  box-shadow: none;
}

.bce.be-is-small .be-sub-header {
  border-bottom: 0;
}

.badgeable-container {
  display: inline-block;
  line-height: 1;
  position: relative;
}

.badgeable-container .badges {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.badgeable-container .badges .bottom-left-badge,
.badgeable-container .badges .bottom-right-badge,
.badgeable-container .badges .top-left-badge,
.badgeable-container .badges .top-right-badge {
  pointer-events: all;
  position: absolute;
}

.badgeable-container .badges .top-left-badge {
  left: 8px;
  top: -6px;
  transform: translateX(-100%);
}

.badgeable-container .badges .top-right-badge {
  left: calc(100% - 8px);
  top: -6px;
}

.badgeable-container .badges .bottom-left-badge {
  bottom: -6px;
  left: 8px;
  transform: translateX(-100%);
}

.badgeable-container .badges .bottom-right-badge {
  bottom: -6px;
  left: calc(100% - 8px);
}

.bcu-item-label {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bcu-item-icon-name {
  cursor: default;
  display: flex;
  height: 50px;
  width: 100%;
}

.bcu-item-icon {
  align-items: center;
  flex: 0 0 50px;
}

.bcu-item-icon,
.bcu-item-name {
  display: flex;
  justify-content: center;
}

.bcu-item-name {
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  line-height: 15px;
  overflow: hidden;
  text-align: left;
}

.bcu-icon-badge .badges .bottom-right-badge {
  bottom: -4px;
  left: calc(100% - 16px);
}

.bcu-progress-container {
  background: #e8e8e8;
  height: 2px;
  margin-right: 40px;
  transition: opacity 0.4s;
  width: 100%;
  z-index: 201;
}

.bcu-progress-container .bcu-progress {
  background: #0061d5;
  box-shadow: 0 1px 5px 0 #e4f4ff;
  height: 2px;
  left: 0;
  max-width: 100%;
  top: 0;
  transition: width 0.1s;
}

.bcu-item-progress {
  align-items: center;
  display: flex;
}

.bcu-progress-label {
  min-width: 31px;
}

.bcu-item-action {
  height: 24px;
  width: 24px;
}

.bcu-item-action .crawler {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.bcu-item-action button {
  display: flex;
}

.bcu-item-action button svg {
  display: block;
}

.bcu-item-action button .be-icon-in-progress {
  height: 24px;
  width: 24px;
}

.bcu-item-action button .be-icon-in-progress svg {
  display: none;
}

.bcu-item-action button:hover .be-icon-in-progress svg {
  display: block;
}

.bcu-item-action button:hover .be-icon-in-progress .crawler {
  display: none;
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
}

.ReactVirtualized__Table__headerRow,
.ReactVirtualized__Table__row {
  -ms-flex-align: center;
  -ms-flex-direction: row;
  -webkit-box-align: center;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0;
}

.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ReactVirtualized__Table__sortableHeaderIcon {
  -ms-flex: 0 0 24px;
  -webkit-box-flex: 0;
  fill: currentColor;
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
}

.bcu-item-row {
  border-bottom: 1px solid rgba(190, 212, 222, 0.4);
  outline: none;
}

.bcu-item-list-action-column:last-child {
  margin-right: 18px;
}

.bcu-drag-drop-overlay {
  bottom: 0;
  display: none;
  left: 0;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 0;
}

.bcu-upload-state {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  text-align: center;
}

.bcu-upload-state svg {
  margin-bottom: 10px;
}

.bcu-upload-state.bcu-is-droppable .bcu-drag-drop-overlay {
  background: #0061d5;
  display: block;
}

.bcu-upload-state.bcu-is-not-droppable .bcu-drag-drop-overlay {
  background: #c82341;
  display: block;
}

.bcu-upload-state.bcu-has-items {
  display: none;
}

.bcu-upload-state.bcu-has-items.bcu-is-droppable,
.bcu-upload-state.bcu-has-items.bcu-is-not-droppable {
  background-color: hsla(0, 0%, 100%, 0.8);
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bcu-upload-state.bcu-has-items.bcu-is-droppable .bcu-drag-drop-overlay,
.bcu-upload-state.bcu-has-items.bcu-is-not-droppable .bcu-drag-drop-overlay {
  display: none;
}

.bcu-upload-state-message + .be-input-btn {
  margin-top: 10px;
}

.bcu-upload-state-message + .be-input-link {
  margin-top: 5px;
}

.bcu-upload-input-container .be-input-link {
  display: inline-block;
}

.bcu-droppable-content {
  display: flex;
  flex: 1 0 200px;
  flex-direction: column;
  position: relative;
}

.bcu-droppable-content .bcu-item-list {
  flex: 1;
  position: relative;
}

.bcu-droppable-content .bcu-item-list .ReactVirtualized__Table__Grid {
  outline: none;
}

.bcu-uploads-manager-action {
  margin: 5px -20px 5px 5px;
}

.bcu-uploads-manager-action .btn-primary {
  display: flex;
}

.bcu-overall-progress-bar {
  align-items: center;
  background: #0061d5;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 60px;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 65px;
  position: relative;
  width: 100%;
}

.bcu-overall-progress-bar:hover {
  background: #0063d9;
}

.bcu-overall-progress-bar .bcu-progress {
  background: #fff;
}

.bcu-overall-progress-bar .bcu-progress-container {
  background: transparent;
}

.bcu-overall-progress-bar .bcu-upload-status {
  flex: none;
  font-size: 16px;
  margin-right: 20px;
}

.be.bcu-uploads-manager-container {
  bottom: 0;
  max-height: 0;
  position: fixed;
  transition: max-height 0.5s;
  width: 100%;
}

.be.bcu-uploads-manager-container .bcu-progress-container {
  margin-right: 0;
}

.be.bcu-uploads-manager-container .bcu-item-progress .bcu-progress-container {
  display: none;
}

.be.bcu-uploads-manager-container .bcu-item-progress .bcu-progress-label {
  margin-left: auto;
}

.be.bcu-uploads-manager-container.bcu-is-visible {
  max-height: 60px;
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-item-list {
  display: none;
  height: 375px;
}

.be.bcu-uploads-manager-container
  .bcu-uploads-manager-item-list
  .buik-btn-plain:focus {
  border: 1px solid #bcbcbc;
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle {
  background: 0 0;
  border: 0;
  bottom: 0;
  height: 10px;
  margin: auto 0;
  position: absolute;
  right: 26px;
  top: 0;
  vertical-align: middle;
  width: 10px;
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:after,
.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:before {
  border: 0 solid #fff;
  content: '';
  height: 6px;
  position: absolute;
  width: 6px;
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:before {
  border-bottom-width: 2px;
  border-left-width: 2px;
  bottom: 0;
  left: 0;
}

.be.bcu-uploads-manager-container .bcu-uploads-manager-toggle:after {
  border-right-width: 2px;
  border-top-width: 2px;
  right: 0;
  top: 0;
}

.be.bcu-uploads-manager-container.bcu-is-expanded {
  max-height: 435px;
}

.be.bcu-uploads-manager-container.bcu-is-expanded
  .bcu-uploads-manager-item-list {
  display: block;
}

.be.bcu-uploads-manager-container.bcu-is-expanded
  .bcu-uploads-manager-toggle:before {
  bottom: 7px;
  left: 7px;
}

.be.bcu-uploads-manager-container.bcu-is-expanded
  .bcu-uploads-manager-toggle:after {
  right: 7px;
  top: 7px;
}

.be.bcu-uploads-manager-container
  .bcu-overall-progress-bar:hover
  .bcu-uploads-manager-toggle:before {
  bottom: -3px;
  left: -3px;
}

.be.bcu-uploads-manager-container
  .bcu-overall-progress-bar:hover
  .bcu-uploads-manager-toggle:after {
  right: -3px;
  top: -3px;
}

.be.bcu-uploads-manager-container.bcu-is-expanded
  .bcu-overall-progress-bar:hover
  .bcu-uploads-manager-toggle:before {
  bottom: 6px;
  left: 6px;
}

.be.bcu-uploads-manager-container.bcu-is-expanded
  .bcu-overall-progress-bar:hover
  .bcu-uploads-manager-toggle:after {
  right: 6px;
  top: 6px;
}

.be.bcu-uploads-manager-container .bcu-item-row {
  padding-right: 5px;
}

.be.bcu-uploads-manager-container .bcu-item-icon-name {
  padding-left: 5px;
}

.be.bcu-uploads-manager-container .bcu-progress {
  box-shadow: none;
}

.be.bcu-uploads-manager-container .bcu-item-list {
  position: relative;
}

.bcu-footer {
  align-items: center;
  background-color: #fcfcfc;
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex: 0 0 70px;
  justify-content: space-between;
  padding: 0 20px;
}

.bcu-footer .bcu-footer-message {
  padding: 0 10px;
  text-align: center;
}

.bcu-footer .bcu-footer-right {
  margin-left: 8px;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'),
    url(https://cdn01.boxcdn.net/fonts/1.0.2/lato/Lato-Regular.woff2)
      format('woff2'),
    url(https://cdn01.boxcdn.net/fonts/1.0.2/lato/Lato-Regular.woff)
      format('woff');
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
    url(https://cdn01.boxcdn.net/fonts/1.0.2/lato/Lato-Bold.woff2)
      format('woff2'),
    url(https://cdn01.boxcdn.net/fonts/1.0.2/lato/Lato-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Angelina'; /*Can be any text*/
  src: local('Angelina'),
    url('../../../../assets/fonts/ANGELINA.TTF') format('truetype');
}

.inline-alert {
  border-radius: 4px;
  color: #222;
  display: none;
  margin: 10px 0;
  padding: 14px 10px;
}

.inline-alert.inline-alert-visible {
  display: block;
}

.inline-alert.inline-alert-warning {
  background-color: #fef7e8;
  border: 1px solid #fad98d;
}

.inline-alert.inline-alert-error {
  background-color: #fdebee;
  border: 1px solid #f69bab;
}

.inline-alert.inline-alert-success {
  background-color: #e9f8f2;
  border: 1px solid #26c281;
}

.inline-alert.inline-alert-info {
  background-color: #e9f2fe;
  border: 1px solid #91c2fd;
}

.inline-alert.inline-alert-generic {
  background-color: #e8e8e8;
  border: 1px solid #909090;
}

.accessibility-hidden {
  left: -9999px;
  position: absolute;
  top: auto;
}

.overlay-wrapper {
  display: inherit;
  position: relative;
}

.overlay-wrapper .overlay {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: rotateY(-10deg) rotateX(-10deg);
  transform-origin: left top;
  transform-style: preserve-3d;
  transition: visibility 0s linear 75ms, opacity 75ms, transform 0.125s;
  visibility: hidden;
  z-index: 90;
}

.overlay-wrapper.is-visible .overlay {
  opacity: 1;
  pointer-events: all;
  transform: rotateY(0deg) rotateX(0deg);
  transition-delay: 0s;
  visibility: visible;
}

.overlay {
  background: #fff;
  border: 1px solid #bcbcbc;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 8px 0;
}

.overlay hr {
  border: 0;
  border-top: 1px solid #bcbcbc;
  height: 0;
  margin: 8px 0;
}

.toggle-overlay-container > .btn {
  margin-bottom: 0;
  margin-left: 0;
}

.toggle-overlay .overlay {
  margin-top: 5px;
}

.btn.pill,
.pill {
  align-items: center;
  background-color: #0061d5;
  border-radius: 3px;
  color: #fff;
  display: flex;
  flex: none;
  height: 24px;
  margin: 1px;
  max-width: 100%;
  padding: 2px 10px;
}

.btn.pill,
.btn.pill .pill-text,
.pill,
.pill .pill-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn.pill .pill-text,
.pill .pill-text {
  display: block;
}

.btn.pill .close-btn,
.pill .close-btn {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  flex: none;
  font-size: 8px;
  font-weight: 700;
  margin: 0 0 0 4px;
  padding: 4px 0 4px 4px;
}

.btn.pill.is-disabled,
.pill.is-disabled {
  opacity: 0.5;
}

.btn.pill.is-disabled .close-btn,
.pill.is-disabled .close-btn {
  cursor: default;
}

.btn.pill.is-invalid,
.pill.is-invalid {
  background-color: #ed3757;
}

.btn.pill.is-selected,
.pill.is-selected {
  box-shadow: inset 0 0 0 1px #fff;
}

.pill-warning {
  background-color: #fef7e8;
  border: 1px solid #fad98d;
  color: #f5b31b;
}

.pill-warning a {
  color: #f5b31b;
}

.pill-error {
  background-color: #fdebee;
  border: 1px solid #f69bab;
}

.pill-error,
.pill-error a {
  color: #ed3757;
}

.pill.pill-cloud-button {
  background-color: #fff;
  border: 1px solid #0061d5;
  color: #0061d5;
  display: inline-block;
  margin: 3px;
}

.pill.pill-cloud-button.is-selected {
  box-shadow: none;
}

.pill.pill-cloud-button:not(.is-disabled):focus {
  border-color: #004eac;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.pill-cloud-container {
  border: 1px solid #e8e8e8;
  overflow-y: scroll;
  padding: 8px;
}

.pill-selector-hidden-input {
  position: absolute;
  visibility: hidden;
}

.pill-selector-wrapper {
  margin: 0 0 20px;
  position: relative;
}

.pill-selector-wrapper .pill-selector-input-wrapper {
  -webkit-font-smoothing: antialiased;
  align-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  cursor: text;
  display: flex;
  flex-flow: row wrap;
  margin-top: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  transition: border-color 0.15s linear, box-shadow 0.15s linear;
  width: 262px;
}

.pill-selector-wrapper .pill-selector-input-wrapper:hover {
  border: 1px solid #004aa2;
}

.pill-selector-wrapper .pill-selector-input-wrapper.is-disabled {
  background-color: #fbfbfb;
  box-shadow: none;
  color: #bcbcbc;
}

.pill-selector-wrapper .pill-selector-input-wrapper.is-disabled:hover {
  border-color: #bcbcbc;
}

.pill-selector-wrapper .pill-selector-input-wrapper.is-focused {
  border-color: #0061d5;
  box-shadow: none;
  outline: 0;
}

.pill-selector-wrapper .pill-selector-input-wrapper.show-error {
  border-color: #ed3757;
}

.pill-selector-wrapper
  .pill-selector-input-wrapper.pill-selector-suggestions-enabled {
  min-height: 80px;
}

.pill-selector-wrapper
  .pill-selector-input-wrapper:not(.show-error)
  .icon-alert {
  display: none;
}

.pill-selector-wrapper .pill-selector-input-wrapper .icon-alert {
  bottom: 10px;
  height: 16px;
  position: absolute;
  right: 10px;
  width: 16px;
}

.pill-selector-wrapper .pill-selector-input-wrapper .icon-alert path {
  fill: #ed3757;
}

.pill-selector-wrapper .pill-selector-input-wrapper .tooltip {
  margin-top: 0 !important;
  right: 10px !important;
  top: calc(100% - 5px) !important;
}

.pill-selector-wrapper .pill-selector-input-wrapper .pills-list {
  margin: 0;
}

.pill-selector-wrapper .pill-selector-input-wrapper .pills-list li {
  float: left;
}

.pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  box-sizing: content-box !important;
  color: #4f4f4f;
  flex: 1;
  height: 18px;
  line-height: 18px;
  margin: 1px;
  min-width: 0;
  overflow: hidden;
  padding: 3px;
  resize: none;
  white-space: nowrap;
}

.pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input:focus,
.pill-selector-wrapper .pill-selector-input-wrapper .pill-selector-input:hover {
  outline: none;
}

.notifications-wrapper {
  height: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 180;
}

.notification {
  align-items: center;
  background-color: #4e4e4e;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: flex;
  font-weight: 700;
  margin: 10px auto;
  max-width: 572px;
  min-height: 48px;
  opacity: 0.9;
  overflow: hidden;
  padding: 10px 10px 10px 20px;
  transition: opacity 0.1s ease-out;
}

.notification.info {
  background-color: #26c281;
}

.notification.warn {
  background-color: #f5b31b;
}

.notification.error {
  background-color: #ed3757;
}

.notification.is-hidden {
  box-shadow: none;
  opacity: 0;
}

.notification > span {
  font-size: 15px;
  margin-right: auto;
  padding-right: 10px;
}

.notification > svg {
  flex: none;
  margin-right: 15px;
}

.notification.wrap > span {
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.notification.ellipsis > span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification a,
.notification button {
  color: #fff;
  flex: none;
}

.notification a.btn.is-disabled,
.notification a.btn:not(.is-disabled),
.notification button.btn.is-disabled,
.notification button.btn:not(.is-disabled) {
  background-color: transparent;
  border-color: #e8e8e8;
  margin: 0 5px;
  padding: 7px 13px;
}

.notification a.close-btn,
.notification button.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  opacity: 0.6;
  outline: none;
  padding: 2px 7px;
}

.be {
  background: #fff;
  border: 0;
  color: #222;
  font-size: 13px;
  height: 100%;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.be,
.be * {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box !important;
  text-rendering: optimizeLegibility;
}

.be * {
  font-family: inherit;
}

.be :after,
.be :before {
  box-sizing: inherit;
  color: inherit;
  font-family: inherit;
  text-rendering: inherit;
}

.be .lnk,
.be a {
  color: #0061d5;
  text-decoration: none;
}

.be .lnk {
  background: transparent none;
  border: none;
  cursor: pointer;
  display: inline;
  filter: none;
  margin: 0;
  padding: 0;
}

.be .lnk,
.be .lnk:active,
.be .lnk:hover {
  box-shadow: none;
  height: auto;
  line-height: inherit;
}

.be .lnk:active,
.be .lnk:hover {
  background: transparent;
}

.be .lnk:active {
  color: #0061d5;
}

.be .lnk.is-disabled:focus,
.be .lnk:active,
.be .lnk:focus,
.be a.is-disabled:focus,
.be a:focus {
  text-decoration: underline;
}

.be .lnk.is-disabled,
.be .lnk.is-disabled:active,
.be .lnk.is-disabled:hover,
.be a.is-disabled,
.be a.is-disabled:active,
.be a.is-disabled:hover {
  color: #909090;
  cursor: default;
  text-decoration: none;
}

.be .lnk-plain {
  color: inherit;
}

.be .lnk-plain:active,
.be .lnk-plain:hover {
  text-decoration: none;
}

.be .lnk-plain.is-disabled,
.be .lnk-plain.is-disabled:active,
.be .lnk-plain.is-disabled:hover {
  color: #909090;
}

.be a svg {
  pointer-events: none;
}

.be .link {
  color: #767676;
  line-height: 1.5em;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  transition: color 0.05s;
  white-space: nowrap;
}

.be .link:hover {
  color: #0061d5;
}

.be .link-group-title {
  color: #a7a7a7;
  font-size: 10px;
  letter-spacing: 0.1em;
  line-height: 28px;
  text-transform: uppercase;
}

.be div[contentEditable='true'],
.be input[type='email'],
.be input[type='number'],
.be input[type='password'],
.be input[type='search'],
.be input[type='tel'],
.be input[type='text'],
.be input[type='url'],
.be textarea {
  -webkit-font-smoothing: antialiased;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #222;
  padding: 7px;
  transition: border-color 0.15s linear, box-shadow 0.1s linear;
  width: 262px;
}

.be div[contentEditable='true']:hover,
.be input[type='email']:hover,
.be input[type='number']:hover,
.be input[type='password']:hover,
.be input[type='search']:hover,
.be input[type='tel']:hover,
.be input[type='text']:hover,
.be input[type='url']:hover,
.be textarea:hover {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15);
}

.be div[contentEditable='true']:focus,
.be input[type='email']:focus,
.be input[type='number']:focus,
.be input[type='password']:focus,
.be input[type='search']:focus,
.be input[type='tel']:focus,
.be input[type='text']:focus,
.be input[type='url']:focus,
.be textarea:focus {
  border: 1px solid #0061d5;
  box-shadow: none;
  outline: 0;
}

.be input:-ms-input-placeholder,
.be input::-moz-placeholder,
.be input::-webkit-input-placeholder {
  color: #d3d3d3;
}

.be div[contentEditable='true'].is-invalid,
.be input[type='email'].is-invalid,
.be input[type='number'].is-invalid,
.be input[type='password'].is-invalid,
.be input[type='search'].is-invalid,
.be input[type='tel'].is-invalid,
.be input[type='text'].is-invalid,
.be input[type='url'].is-invalid,
.be textarea.is-invalid {
  border: 1px solid #ed3757;
}

.be div[contentEditable='true'].is-invalid:focus,
.be input[type='email'].is-invalid:focus,
.be input[type='number'].is-invalid:focus,
.be input[type='password'].is-invalid:focus,
.be input[type='search'].is-invalid:focus,
.be input[type='tel'].is-invalid:focus,
.be input[type='text'].is-invalid:focus,
.be input[type='url'].is-invalid:focus,
.be textarea.is-invalid:focus {
  border: 1px solid #f44;
}

.be div[contentEditable='true']:disabled,
.be input[type='email']:disabled,
.be input[type='number']:disabled,
.be input[type='password']:disabled,
.be input[type='search']:disabled,
.be input[type='tel']:disabled,
.be input[type='text']:disabled,
.be input[type='url']:disabled,
.be textarea:disabled {
  background-color: #fbfbfb;
  border-color: #e8e8e8;
  box-shadow: none;
  color: #bcbcbc;
}

.be div[contentEditable='true']:disabled:hover,
.be input[type='email']:disabled:hover,
.be input[type='number']:disabled:hover,
.be input[type='password']:disabled:hover,
.be input[type='search']:disabled:hover,
.be input[type='tel']:disabled:hover,
.be input[type='text']:disabled:hover,
.be input[type='url']:disabled:hover,
.be textarea:disabled:hover {
  border-color: #e8e8e8;
}

.be input[type='search'] {
  -webkit-appearance: textfield;
}

.be input[type='search']::-webkit-search-cancel-button,
.be input[type='search']::-webkit-search-results-button {
  display: none;
}

.be input::-ms-clear {
  display: none;
}

.be textarea {
  vertical-align: top;
}

.be .input-x-small,
.be .input-x-small[type] {
  width: 100px;
}

.be .input-small,
.be .input-small[type] {
  width: 150px;
}

.be .input-medium,
.be .input-medium[type] {
  width: 300px;
}

.be .input-large,
.be .input-large[type] {
  width: 375px;
}

.be .input-x-large,
.be .input-x-large[type] {
  width: 475px;
}

.be .input-full,
.be .input-full[type] {
  width: 100%;
}

.be .label-sep:after {
  content: ':';
}

.be .select-container .select-button,
.be .select-container select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  display: inline-block;
  height: 34px;
  padding: 5px 0 5px 10px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.be .select-container select {
  background: none;
  border: none;
  color: #222;
  padding-right: 25px;
}

.be .select-container .select-button:disabled,
.be .select-container select:disabled {
  cursor: default;
}

.be .select-container {
  display: inline-block;
  position: relative;
}

.be .select-container .select-button,
.be .select-container .select-container-inner {
  display: inline-block;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
}

.be .select-container .select-overlay {
  box-sizing: border-box;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.be .select-container .select-button,
.be .select-container .select-overlay {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.05);
  transition: border-color 0.15s linear, box-shadow 0.1s linear;
}

.be .select-container .select-button:before,
.be .select-container .select-overlay:before {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #4e4e4e;
  content: '';
  height: 0;
  position: absolute;
  right: 11px;
  top: 15px;
  transition: transform 0.2s;
  width: 0;
}

.be .select-container .select-button[aria-expanded='true']:before {
  transform: rotate(180deg);
}

.be .select-container .select-button {
  color: #222;
  padding-right: 25px;
}

.be .select-container .select-button:focus,
.be .select-container select:focus {
  outline: none;
}

.be .select-container .select-button:focus,
.be .select-container select:focus ~ .select-overlay {
  border: 1px solid #0061d5;
}

.be .select-container .select-button:hover,
.be .select-container select:hover ~ .select-overlay {
  border: 1px solid #4c4c4c;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.be .select-container .select-button:disabled,
.be .select-container select:disabled {
  color: #bcbcbc;
}

.be .select-container .select-button:disabled,
.be .select-container select:disabled ~ .select-overlay {
  background-color: #fbfbfb;
  border-color: #e8e8e8;
  box-shadow: none;
}

.be .select-container .select-button:disabled:hover,
.be .select-container select:disabled:hover ~ .select-overlay {
  box-shadow: none;
}

.be .select-container .select-button:disabled:before,
.be .select-container select:disabled ~ .select-overlay:before {
  border-top: 3px solid #d5d5d5;
}

.be .select-container.is-invalid .select-button:focus ~ .select-overlay,
.be .select-container.is-invalid select + .select-overlay,
.be .select-container.is-invalid select:focus ~ .select-overlay,
.be .select-container.is-invalid select:hover + .select-overlay {
  border: 1px solid #ed3757;
}

.be .select-container .select-button:active {
  background-color: #e8e8e8;
}

.be .select-container-x-small {
  width: 50px;
}

.be .select-container-small {
  width: 100px;
}

.be .select-container-medium {
  width: 200px;
}

.be .select-container-large {
  width: 262px;
}

.be .select-container-x-large {
  width: 345px;
}

.be .select-container.huge {
  width: 500px;
}

.be select::-ms-expand {
  display: none;
}

.be ::-webkit-input-placeholder {
  font-weight: 400;
  opacity: 0.6;
}

.be ::-moz-placeholder {
  font-weight: 400;
  opacity: 0.6;
}

.be :-ms-input-placeholder {
  font-weight: 400;
  opacity: 0.6;
}

.be ::-webkit-file-upload-button {
  cursor: pointer;
}

.be ul {
  margin: 0;
  padding: 0;
}

.be ul li {
  list-style: none;
}

.be .btn-plain,
.be .select-button,
.be input,
.be select,
.be textarea {
  font: inherit;
}

.ReactModal__Body--open .be {
  position: relative;
}

.be-modal {
  color: #222;
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 20px;
}

.be-modal,
.be-modal * {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box !important;
  text-rendering: optimizeLegibility;
}

.be-modal * {
  font-family: inherit;
}

.be-modal :after,
.be-modal :before {
  box-sizing: inherit;
  color: inherit;
  font-family: inherit;
  text-rendering: inherit;
}

.be-modal ::-webkit-input-placeholder {
  font-weight: 400;
  opacity: 0.6;
}

.be-modal ::-moz-placeholder {
  font-weight: 400;
  opacity: 0.6;
}

.be-modal :-ms-input-placeholder {
  font-weight: 400;
  opacity: 0.6;
}

.be-modal ::-webkit-file-upload-button {
  cursor: pointer;
}

.be-modal ul {
  margin: 0;
  padding: 0;
}

.be-modal ul li {
  list-style: none;
}

.be-modal .btn-plain,
.be-modal .select-button,
.be-modal input,
.be-modal select,
.be-modal textarea {
  font: inherit;
}

.be-app-element {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.be-input-link {
  color: #0061d5;
  cursor: pointer;
  display: block;
}

.be-input-link:active,
.be-input-link:focus,
.be-input-link:hover {
  text-decoration: underline;
}

.be-input-link input {
  display: none;
}

.be-input-btn {
  cursor: pointer;
  display: block;
}

.be-hidden,
.be-input-btn input {
  display: none;
}

.btn-group {
  position: relative;
}

.btn-group .toggle-overlay {
  display: inline;
}

.btn-group .toggle-overlay > .btn {
  border-radius: 0 4px 4px 0;
  margin-left: 0;
  padding-left: 11px;
  padding-right: 11px;
}

.btn-group .toggle-overlay > .btn.btn-primary {
  border-left-color: #004aa2;
}

.btn-group .toggle-overlay .icon-caret-down:after {
  content: '';
}

.btn-group .toggle-overlay .icon-caret-down path {
  fill: #fff;
}

.btn-group .toggle-overlay .overlay-wrapper {
  position: static;
}

.btn-group .toggle-overlay .overlay-wrapper .overlay {
  left: 0;
}

.btn-group > .btn {
  border-radius: 0;
  margin: 5px 0 5px -1px;
}

.btn-group > .btn.btn-primary {
  border-right-color: #004aa2;
  margin: 5px 0;
}

.btn-group > .btn.btn-primary.is-selected {
  background-color: #004eac;
  border-color: #003e89;
  box-shadow: none;
  color: #fff;
}

.btn-group > .btn.btn-primary:last-of-type {
  border-right-color: #0061d5;
}

.btn-group > .btn:first-of-type {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.btn-group > .btn:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.btn-group > .btn.is-selected {
  background-color: #e8e8e8;
  border-color: #767676;
  box-shadow: none;
  color: #4e4e4e;
  z-index: 2;
}

.btn-group > .btn:focus {
  z-index: 3;
}

.btn-group.is-disabled > .btn {
  background-color: #fbfbfb;
  border: 1px solid #bcbcbc;
  box-shadow: none;
  color: #767676;
  cursor: default;
  opacity: 0.4;
}

.btn-group.is-disabled > .btn-primary {
  background-color: #0061d5;
  border-color: #0061d5 #004aa2 #0061d5 #0061d5;
  color: #fff;
}

.btn-group.is-disabled > .btn-primary:last-of-type {
  border-right-color: #0061d5;
}

.menu-toggle {
  align-items: center;
  display: flex;
}

.menu-toggle > .toggle-arrow {
  flex: none;
  margin-left: 5px;
  transition: transform 0.2s ease-in;
}

.dropdown-menu-enabled .menu-toggle > .toggle-arrow,
.is-toggled .menu-toggle > .toggle-arrow {
  transform: rotate(180deg);
}

.lnk .menu-toggle > .toggle-arrow {
  margin-top: 2px;
}

.lnk .menu-toggle > .toggle-arrow path {
  fill: #0061d5;
}

.btn-primary .menu-toggle .fill-color {
  fill: #fff;
}

.lnk.is-disabled .menu-toggle .fill-color {
  fill: #909090;
}

.submenu-target {
  position: relative;
}

.submenu-target:after {
  border-color: transparent transparent transparent #bcbcbc;
  border-style: solid none solid solid;
  border-width: 4px;
  content: '';
  position: absolute;
  right: 10px;
  top: 12px;
}

.submenu-target .submenu {
  left: 100%;
  position: absolute;
  right: auto;
  top: 0;
}

.submenu-target .submenu.is-left-aligned {
  left: auto;
  right: 100%;
}

.submenu-target .submenu.is-hidden {
  left: -9999px;
  right: auto;
}

.submenu-target .submenu.is-bottom-aligned {
  bottom: 0;
  top: auto;
}

.be-pagination-dropdown-menu {
  margin-bottom: 5px;
  max-height: 300px;
  overflow: auto;
}

.be-pagination {
  display: flex;
}

.be-pagination .be-pagination-count {
  align-items: center;
  display: flex;
}

.be-is-small .be-pagination .be-pagination-count {
  display: none;
}

.be-pagination .be-pagination-toggle {
  margin-left: 5px;
  margin-right: 5px;
}

.be-empty {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.be-progress-container {
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.4s;
  width: 100%;
  z-index: 190;
}

.be-progress-container .be-progress {
  background: #0061d5;
  box-shadow: 0 1px 5px 0 #e4f4ff;
  height: 2px;
  max-width: 100%;
  transition: width 0.4s;
}

.be-item-label {
  display: block;
  outline: none;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.be-date-capitalize {
  text-transform: capitalize;
}

.bdl-GridView .bdl-ItemSubDetails-modifiedBy,
.bdl-GridView .bdl-ItemSubDetails-size {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bce-item-row .bdl-ItemSubDetails-size:before {
  content: '\2022';
  font-size: 8px;
  padding-left: 3px;
  padding-right: 3px;
  vertical-align: middle;
}

.be-item-name .be-item-details {
  color: #767676;
  font-size: 11px;
}

.be-item-name {
  line-height: 15px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: normal;
}

.be-item-icon {
  align-items: center;
  display: flex;
  flex: 0 0 50px;
  justify-content: center;
}

.be-is-small .be-item-icon {
  flex: 0 0 30px;
}

.be select.be-share-access-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-image: linear-gradient(45deg, transparent 50%, #767676 0),
    linear-gradient(135deg, #767676 50%, transparent 0);
  background-position: right 14px center, right 10px center;
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  border: 1px solid #bdc3c7;
  border-radius: 2px;
  color: inherit;
  cursor: pointer;
  padding: 5px 25px 5px 10px;
}

.be select.be-share-access-select:hover {
  border-color: #0061d5;
}

.be select.be-share-access-select:active,
.be select.be-share-access-select:focus {
  background-image: linear-gradient(45deg, transparent 50%, #0061d5 0),
    linear-gradient(135deg, #0061d5 50%, transparent 0);
  border-color: #0061d5;
  color: #0061d5;
  outline: none;
}

.be select.be-share-access-select:disabled {
  background-image: linear-gradient(45deg, transparent 50%, #e8e8e8 0),
    linear-gradient(135deg, #e8e8e8 50%, transparent 0);
  border-color: #e8e8e8;
  color: #e8e8e8;
}

.be select.be-share-access-select:-moz-focusring,
.be select.be-share-access-select::-moz-focus-inner {
  color: transparent !important;
  text-shadow: 0 0 0 #222 !important;
}

.be select.be-share-access-select::-ms-expand {
  display: none !important;
}

.checkbox-label {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  display: inline-flex;
  position: relative;
  user-select: none;
}

.checkbox-label > input[type='checkbox'] {
  flex: none;
  height: 14px;
  margin: 3px 0 0;
  opacity: 0;
  width: 14px;
}

.checkbox-label > input[type='checkbox'] + span:before {
  background: #fff;
  border: 1px solid #909090;
  border-radius: 2px;
  content: '';
  height: 14px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-label > input[type='checkbox'] + span:after {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-bottom: 2px solid #0061d5;
  border-right: 2px solid #0061d5;
  content: '';
  height: 7px;
  left: 5px;
  position: absolute;
  top: 6px;
  transform: rotate(45deg) scale(0) translateZ(0);
  transition: transform 0.1s ease;
  width: 4px;
}

.checkbox-label > input[type='checkbox']:checked + span:after {
  transform: rotate(45deg) scale(1) translateZ(0);
}

.checkbox-label > input[type='checkbox']:focus + span:before {
  border-color: #0061d5;
}

.checkbox-label > input[type='checkbox']:disabled + span:before {
  background-color: #fbfbfb;
  border: 1px solid #d3d3d3;
}

.checkbox-label > input[type='checkbox']:disabled + span:after {
  border-color: #bcbcbc;
}

.checkbox-label > input[type='checkbox'] + span + span {
  margin-left: 10px;
}

.checkbox-tooltip-wrapper {
  align-items: center;
  display: flex;
}

.checkbox-tooltip-wrapper > .info-tooltip {
  flex: none;
  height: 16px;
  margin-left: 5px;
  position: relative;
  width: 16px;
}

.checkbox-tooltip-wrapper > .info-tooltip > svg path {
  fill: #0061d5;
}

.checkbox-container {
  margin: 0 0 20px;
}

.checkbox-container.is-disabled .checkbox-label {
  color: #909090;
}

.checkbox-container > .label {
  margin: 8px 0;
}

.checkbox-description,
.checkbox-subsection {
  margin-left: 24px;
  margin-top: 2px;
}

.checkbox-description {
  color: #909090;
}

.radio-label {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  display: inline-flex;
  position: relative;
  user-select: none;
}

.radio-label > input[type='radio'] {
  flex: none;
  height: 14px;
  margin: 0;
  opacity: 0;
  width: 14px;
}

.radio-label > input[type='radio'] + span:before {
  background-color: #fff;
  border: 7px solid #fff;
  border-radius: 9001px;
  box-shadow: 0 0 0 1px #909090, 0 -1px 1px rgba(0, 0, 0, 0.4);
  content: '';
  height: 14px;
  left: 0;
  position: absolute;
  top: 3px;
  transition: border-width 0.1s;
  width: 14px;
}

.radio-label > input[type='radio']:checked + span:before {
  background-color: #0061d5;
  border: 3px solid #fff;
}

.radio-label > input[type='radio']:focus + span:before {
  box-shadow: 0 0 0 1px #0061d5;
}

.radio-label > input[type='radio']:disabled + span:before {
  background-color: #bcbcbc;
  border-color: #fbfbfb;
  box-shadow: 0 0 0 1px #d3d3d3;
}

.radio-label > input[type='radio'] + span + span {
  margin-left: 10px;
}

.radio-label > input[type='radio']:disabled + span + span {
  color: #bcbcbc;
}

.radio-container {
  margin: 0 0 20px;
}

.radio-description {
  color: #909090;
  margin-left: 24px;
  margin-top: 2px;
}

.bcp-item-grid {
  flex: 1;
}

.bcp-item-grid .ReactVirtualized__Table__Grid {
  outline: none;
}

.bcp-item-row {
  border-bottom: 1px solid transparent;
  border-left: 2px solid transparent;
  border-top: 1px solid #e8e8e8;
  outline: none;
}

.bcp-item-row.bcp-item-row-selected {
  background-color: #e4f4ff;
  border-top-color: #bed4de;
}

.bcp-item-row.bcp-item-row-selected .be-item-label,
.be .bcp-item-row.bcp-item-row-selected .btn-plain {
  color: #1a5976;
  outline: none;
}

.bcp-item-row:first-of-type {
  border-top-color: transparent !important;
}

.bcp-item-row:last-child {
  border-bottom: 1px solid #e8e8e8;
}

.bcp-item-row:last-child.bcp-item-row-selected {
  border-bottom-color: #bed4de;
}

.bcp-item-row.bcp-item-row-unselectable .ReactVirtualized__Table__rowColumn {
  opacity: 0.3;
}

.bcp-item-row select.bcp-shared-access-select {
  background-image: none;
  border-color: transparent;
}

.bcp-item-row select.bcp-shared-access-select:active,
.bcp-item-row select.bcp-shared-access-select:focus {
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, #0061d5 0),
    linear-gradient(135deg, #0061d5 50%, transparent 0);
  border-color: #0061d5;
  color: #0061d5;
  outline: none;
}

.bcp-item-row .checkbox-container,
.bcp-item-row .radio-container {
  margin: 0 0 0 1px;
}

.bcp-item-row .checkbox-container input,
.bcp-item-row .checkbox-container span,
.bcp-item-row .radio-container input,
.bcp-item-row .radio-container span {
  cursor: pointer;
}

.bcp-item-row:active,
.bcp-item-row:focus,
.bcp-item-row:hover {
  background-color: rgba(228, 244, 255, 0.4);
  border-left-color: #1a5976;
}

.bcp-item-row:active select.bcp-shared-access-select,
.bcp-item-row:focus select.bcp-shared-access-select,
.bcp-item-row:hover select.bcp-shared-access-select {
  background-color: #fff;
  background-image: linear-gradient(45deg, transparent 50%, #767676 0),
    linear-gradient(135deg, #767676 50%, transparent 0);
  border-color: #d3d3d3;
}

.bcp-item-row:active .be-item-label,
.bcp-item-row:focus .be-item-label,
.bcp-item-row:hover .be-item-label,
.be .bcp-item-row:active .btn-plain,
.be .bcp-item-row:focus .btn-plain,
.be .bcp-item-row:hover .btn-plain {
  color: #1a5976;
}

.bcp-item-row.bcp-item-row-selected + .bcp-item-row {
  border-top-color: #bed4de;
}

.bcp-content {
  display: flex;
  flex: 1 0 160px;
  flex-direction: column;
  position: relative;
}

.bcp-share-access-loading {
  align-items: center;
  display: flex;
  height: 30px;
}

.bcp-footer {
  align-items: center;
  background-color: #fcfcfc;
  border-top: 1px solid #e8e8e8;
  display: flex;
  flex: 0 1 70px;
  justify-content: space-between;
  padding: 0 20px;
}

.bcp-footer .bcp-footer-left {
  flex: 0 1 100%;
  min-width: 0;
}

.bcp-footer .bcp-selected {
  margin: 0;
  max-width: 100%;
}

.bcp-footer .bcp-selected .btn-content {
  display: inline-block;
  line-height: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.bcp-footer .bcp-selected-max {
  animation: be_pulse 0.5s linear 2;
  color: #c82341;
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;
}

.bcp-footer .bcp-footer-actions .btn {
  height: 32px;
  padding: 0;
  width: 40px;
}

.bcp-footer .bcp-footer-actions .btn:first-of-type {
  border-right: 0;
}

.bcp-footer .bcp-footer-actions .btn-primary svg {
  fill: #fff;
}

.bcp-footer .bcp-footer-right {
  display: flex;
  flex: 1 0 auto;
}

.bcp-footer .btn-group {
  margin-left: 5px;
  margin-right: 5px;
}

@keyframes be_pulse {
  0%,
  to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.be-modal .be-modal-btns {
  display: flex;
  justify-content: center;
  padding: 15px 0 0;
}

.be-modal .be-modal-btns .btn {
  margin-left: 8px;
}

.be-modal .be-modal-error {
  color: #c82341;
}

.be-modal-dialog-content,
.be-modal-dialog-content-full-bleed,
.be-modal-wrapper-content {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.be-modal-dialog-overlay,
.be-modal-wrapper-overlay {
  background-color: rgba(51, 51, 51, 0.4);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.be-modal-dialog-content {
  border-radius: 4px;
  bottom: auto;
  left: 50%;
  margin-right: -50%;
  padding: 25px;
  position: absolute;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

.be-is-small .be-modal-dialog-content {
  width: 340px;
}

.be-modal-dialog-overlay {
  position: absolute;
}

.be.bcp {
  min-width: 300px;
}

/**BOX STORAGE ENDS**/
